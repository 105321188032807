export const fetchAbi = async () => {
  try {
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!abiResponse.ok) {
      throw new Error(`HTTP error! status: ${abiResponse.status}`);
    }

    const abi = await abiResponse.json();
    return abi; // Return the parsed abi data
  } catch (err) {
    console.error("Failed to fetch abi:", err);
    throw err; // Re-throw the error to be handled by the caller
  }
};
