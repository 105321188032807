import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

// Initialize GA4 once
if (process.env.NODE_ENV === "production" && GA_MEASUREMENT_ID) {
  ReactGA.initialize(GA_MEASUREMENT_ID);
}

const trackPage = (page) => {
  if (process.env.NODE_ENV === "production" && GA_MEASUREMENT_ID) {
    // Using event tracking for pageviews (GA4 recommended approach)
    ReactGA.send({
      hitType: "pageview",
      page,
      title: page,
    });
  } else {
    console.log(
      `Mocked pageview for "${page}" in ${process.env.NODE_ENV} environment`
    );
  }
};

export default trackPage;
