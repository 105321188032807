import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const Team = ({
  className,
  topOuterDivider = false,
  bottomOuterDivider = false,
  topDivider = false,
  bottomDivider = false,
  hasBgColor = false,
  invertColor = false,
  pushLeft = false,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Who are we?",
    paragraph: "The team",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-row">
              <div
                className="tiles-item reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <span className="testimonial-item-name text-color-high">
                    AE
                  </span>
                  <p>the developer</p>
                  <div className="testimonial-item-footer text-xs mt-8 mb-0 has-top-divider">
                    <Image
                      src={require("./../../assets/images/funky_planet_a_e.png")}
                      alt="AE"
                      width={250}
                      height={250}
                      className="centered-image"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <span className="testimonial-item-name text-color-high">
                    Kaiserin
                  </span>
                  <p>the nicest</p>
                  <div className="testimonial-item-footer text-xs mt-8 mb-0 has-top-divider">
                    <Image
                      src={require("./../../assets/images/funky_planet_kaiserin.png")}
                      alt="Kaiserin"
                      width={250}
                      height={250}
                      className="centered-image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="tiles-row">
              <div
                className="tiles-item reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <span className="testimonial-item-name text-color-high">
                    AD
                  </span>
                  <p>the decider</p>
                  <div className="testimonial-item-footer text-xs mt-8 mb-0 has-top-divider">
                    <Image
                      src={require("./../../assets/images/funky_planet_a_d.png")}
                      alt="AD"
                      width={250}
                      height={250}
                      className="centered-image"
                    />
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-right"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <span className="testimonial-item-name text-color-high">
                    Austin B.
                  </span>
                  <p>the ... (Who knows?)</p>
                  <div className="testimonial-item-footer text-xs mt-8 mb-0 has-top-divider">
                    <Image
                      src={require("./../../assets/images/funky_planet_austin_b.png")}
                      alt="Austin B."
                      width={250}
                      height={250}
                      className="centered-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Team.propTypes = propTypes;

export default Team;
