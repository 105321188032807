import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "../../redux/data/dataActions";
import { truncate } from "../../utils/Utils";

const Header = ({
  className,
  bottomOuterDivider = false,
  bottomDivider = false,
}) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.data.smartContract);

  const handleConnectWallet = (e) => {
    e.preventDefault();
    dispatch(connectWallet());
  };

  const headerClasses = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const innerClasses = classNames(
    "site-header-inner",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <header className={headerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <nav className={classNames("header-nav")}>
            <div className="header-nav-inner">
              <ul className="list-reset header-nav-right">
                <li>
                  {account ? (
                    <div
                      className={classNames(
                        "button button-primary button-wide-mobile button-sm",
                        "disabled-link"
                      )}
                      style={{ cursor: "default" }}
                    >
                      {truncate(account)}
                    </div>
                  ) : (
                    <Link
                      to="#"
                      className="button button-primary button-wide-mobile button-sm"
                      onClick={handleConnectWallet}
                    >
                      Connect wallet
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

export default Header;
