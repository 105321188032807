import React, { useRef, useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchConfigDataAction,
  fetchSmartContractData,
  connectWeb3Contract,
  checkWalletConnection,
} from "./redux/data/dataActions";
import ScrollReveal from "./utils/ScrollReveal";
import trackPage from "./services/analyticsService";

import LayoutDefault from "./layouts/LayoutDefault";
import Home from "./views/Home";

const App = () => {
  const dispatch = useDispatch();
  const childRef = useRef();
  const location = useLocation();

  // Initialize services and data
  useEffect(() => {
    const initApp = async () => {
      try {
        await dispatch(fetchConfigDataAction());
        let web3Connection = await dispatch(connectWeb3Contract());
        if (web3Connection) {
          await dispatch(fetchSmartContractData());
          await dispatch(checkWalletConnection());
        }
      } catch (err) {
        console.error("Error during initialization:", err);
      }
    };

    initApp();
  }, [dispatch]);

  // Analytics Service
  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    if (childRef.current) {
      try {
        childRef.current.init();
      } catch (err) {
        console.error("Error initializing childRef:", err);
      }
    }
    trackPage(page);
    return () => {
      document.body.classList.remove("is-loaded");
    };
  }, [location]);

  return (
    <ScrollReveal ref={childRef}>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutDefault>
              <Home />
            </LayoutDefault>
          }
        />
      </Routes>
    </ScrollReveal>
  );
};

export default App;
