import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSmartContractData } from "../../redux/data/dataActions";
import { fromWei, sendTransaction } from "../../services/web3ContractService";

import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import { Link } from "react-router-dom";
import LogoMint from "../layout/partials/LogoMint";

const propTypes = {
  ...SectionProps.types,
};

const Mint = ({
  className,
  topOuterDivider = false,
  bottomOuterDivider = false,
  topDivider = false,
  bottomDivider = false,
  hasBgColor = false,
  invertColor = false,
  ...props
}) => {
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    `Click mint to get your Funky Planet.`
  );
  const [mintAmount, setMintAmount] = useState(1);

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const handleMint = async () => {
    try {
      setClaimingNft(true);
      setFeedback(`Minting your ${data.config.nftName}...`);

      let cost = data.smartContract.cost;
      let gasLimit = data.config.gasLimit;
      let totalCostWei = String(cost * mintAmount);
      let totalGasLimit = String(gasLimit * mintAmount);

      const transactionOptions = {
        to: data.config.contractAddress,
        from: data.smartContract.account,
        gasLimit: String(totalGasLimit),
        value: totalCostWei,
      };

      const receipt = await sendTransaction(
        "mint",
        [mintAmount],
        transactionOptions
      );

      console.log(receipt);
      setFeedback(
        `WOW, the Funky Planet is yours! Go visit Opensea.io to view it.`
      );
      dispatch(fetchSmartContractData());
    } catch (err) {
      console.log(err);
      setFeedback("Sorry, something went wrong. Please try again later.");
    } finally {
      setClaimingNft(false);
    }
  };

  const decrementMintAmount = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const incrementMintAmount = () => {
    if (mintAmount < data.smartContract.maxMintAmount) {
      setMintAmount(mintAmount + 1);
    }
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Funky Planets NFT
            </h2>
            <div className="container-sm">
              <p
                className="m-0 mb-24 reveal-from-bottom"
                data-reveal-delay="400"
              >
                At the time this NFT collection was created, NASA had registered
                4,525 exoplanets. This collection brings a{" "}
                <b>funky, imaginative twist</b> to them! Each planet in this
                collection is <b>randomly and algorithmically generated</b>,
                featuring unique traits such as{" "}
                <b>
                  background, shape, rings, presence of life, technological
                  advancement, and more
                </b>
                . Some planets even include{" "}
                <b>satellites, water, or entire cities</b>.
              </p>
              <p
                className="m-0 mb-24 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Now, you have the opportunity to <b>own your own planet</b>{" "}
                complete with its own identity and details. Each NFT also
                includes a <b>QR code</b>, allowing you to access and explore
                your planet’s information instantly.
              </p>
              <div style={{ marginBottom: "24px" }}>
                <LogoMint />
              </div>
              <div style={{ fontSize: "15px" }}>
                <p style={{ marginBottom: "-10px" }}>
                  Planets 1 to 1000 conts 0.05 ETH
                </p>
                <p style={{ marginBottom: "-10px" }}>
                  Planets 1001 to 2000 conts 0.055 ETH
                </p>
                <p style={{ marginBottom: "-10px" }}>
                  Planets 2001 to 3000 conts 0.0605 ETH
                </p>
                <p style={{ marginBottom: "-10px" }}>
                  Planets 3001 to 4000 conts 0.0668 ETH
                </p>
                <p style={{ marginBottom: "-10px" }}>
                  Planets 4000 to 4525 conts 0.0732 ETH
                </p>
              </div>
              {data.smartContract.totalSupply ? (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "#25282c",
                      borderRadius: "10px",
                      marginTop: "25px",
                      paddingBlock: "30px",
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "50px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {data.smartContract.totalSupply} / {data.config.maxSupply}
                    </p>
                    {Number(data.smartContract.totalSupply) >=
                    data.config.maxSupply ? (
                      <>
                        <p>The initial sale has sold out.</p>
                        <p>
                          You can still find {data.config.nftName} on{" "}
                          <a
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            href={data.config.marketPlaceOpenseaLink}
                          >
                            Opensea
                          </a>{" "}
                          and{" "}
                          <a
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            href={data.config.marketPlaceRaribleLink}
                          >
                            Rarible
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <p style={{ whiteSpace: "pre-line" }}>
                          1 {data.config.symbol} costs{" "}
                          {fromWei(data.smartContract.cost, "ether")}{" "}
                          {data.config.network.symbol}. {"\n"}Excluding gas
                          fees.
                        </p>
                        {data.smartContract.account !== null ? (
                          <>
                            <p>{feedback}</p>
                            <ButtonGroup>
                              <Link
                                to=""
                                className="button button-primary button-wide-mobile button-sm"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </Link>
                              <span>{mintAmount}</span>
                              <Link
                                to=""
                                className="button button-primary button-wide-mobile button-sm"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </Link>
                            </ButtonGroup>
                            <ButtonGroup>
                              <Link
                                to=""
                                className="button button-primary button-wide-mobile button-sm"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleMint();
                                }}
                              >
                                {claimingNft
                                  ? "The system is claimning your NFT"
                                  : "Mint"}
                              </Link>
                            </ButtonGroup>
                          </>
                        ) : (
                          <p>Connect your wallet</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Mint.propTypes = propTypes;

export default Mint;
