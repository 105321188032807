import Web3 from "web3";

let web3Instance = null;
let smartContractInstance = null;

/**
 * Initializes the Web3 instance and the smart contract.
 * @param {Object} provider - The Web3 provider.
 * @param {Array} ABI - The ABI of the smart contract.
 * @param {string} contractAddress - The address of the smart contract.
 */
export const initializeBlockchain = (provider, ABI, contractAddress) => {
  web3Instance = new Web3(provider);
  smartContractInstance = new web3Instance.eth.Contract(ABI, contractAddress);
};

/**
 * Retrieves the initialized Web3 instance.
 * @returns {Object} Web3 instance.
 */
export const getWeb3 = () => {
  if (!web3Instance) {
    throw new Error("Web3 instance not initialized!");
  }
  return web3Instance;
};

/**
 * Retrieves the initialized smart contract instance.
 * @returns {Object} Smart contract instance.
 */
export const getSmartContract = () => {
  if (!smartContractInstance) {
    throw new Error("Smart contract instance not initialized!");
  }
  return smartContractInstance;
};

/**
 * Retrives smart contract information
 * @returns {Object} Smart contract information
 */
export const getSmartContractInformation = async () => {
  if (!smartContractInstance) {
    throw new Error("Smart contract instance not initialized!");
  }
  
  let totalSupply = await smartContractInstance.methods.totalSupply().call();
  let cost = await smartContractInstance.methods.getCostToMint().call();
  let maxMintAmount = await smartContractInstance.methods.maxMintAmount().call();
  
  return { totalSupply, cost, maxMintAmount };
};

/**
 * Calls a smart contract method.
 * @param {string} methodName - The name of the method to call.
 * @param {Array} params - Parameters for the method.
 * @returns {Promise<any>} Result of the method call.
 */
export const callMethod = async (methodName, params = []) => {
  if (!smartContractInstance) {
    throw new Error("Smart contract instance not initialized!");
  }
  return smartContractInstance.methods[methodName](...params).call();
};

/**
 * Sends a transaction to a smart contract method.
 * @param {string} methodName - The name of the method to send.
 * @param {Array} params - Parameters for the method.
 * @param {Object} transactionOptions - Transaction options (from, value, gas, etc.).
 * @returns {Promise<any>} Transaction receipt.
 */
export const sendTransaction = async (
  methodName,
  params = [],
  transactionOptions = {}
) => {
  if (!smartContractInstance) {
    throw new Error("Smart contract instance not initialized!");
  }
  return new Promise((resolve, reject) => {
    smartContractInstance.methods[methodName](...params)
      .send(transactionOptions)
      .once("error", reject)
      .then(resolve);
  });
};

/**
 * Utility function to convert value to Wei.
 * @param {string | number} value - The value to convert.
 * @returns {string} Value in Wei.
 */
export const toWei = (value) => {
  if (!web3Instance) {
    throw new Error("Web3 instance not initialized!");
  }
  return web3Instance.utils.toWei(value.toString(), "ether");
};

/**
 * Utility function to convert Wei to Ether.
 * @param {string | number} value - The value in Wei.
 * @returns {string} Value in Ether.
 */
export const fromWei = (value) => {
  if (!web3Instance) {
    throw new Error("Web3 instance not initialized!");
  }
  return web3Instance.utils.fromWei(value.toString(), "ether");
};
