const initialState = {
  loading: false,
  error: false,
  errorMsg: "",
  config: {
    contractAddress: "",
    scanLink: "",
    network: {
      name: "",
      symbol: "",
      id: 0,
    },
    nftName: "",
    nftSymbol: "",
    maxSupply: 0,
    gasLimit: 0,
  },
  smartContract: {
    totalSupply: false,
    cost: 0,
    maxMintAmount: 0,
    account: null,
  },
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_LOADING_DATA":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "FETCH_CONFIG_DATA":
      return {
        ...state,
        loading: false,
        config: action.payload,
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case "CONNECTION_SUCCESS_CONTRACT":
      return {
        ...state,
        loading: false,
      };
    case "CONNECTION_SUCCESS_ACCOUNT":
      return {
        ...state,
        loading: false,
        smartContract: {
          ...state.smartContract,
          account: action.payload.account,
        },
      };
    case "CHECK_SMART_CONTRACT_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        smartContract: {
          ...state.smartContract,
          ...action.payload,
        },
        error: false,
        errorMsg: "",
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        smartContract: {
          ...state.smartContract,
          account: action.payload.account,
        },
      };
    case "CONNECTION_FAILED":
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
        smartContract: {
          totalSupply: false,
          cost: 0,
          maxMintAmount: 0,
          account: null,
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
