import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./data/dataReducer";

const store = configureStore({
  reducer: {
    data: dataReducer,
  },
  devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in non-production environments
});

export default store;
