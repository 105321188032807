import {
  initializeBlockchain,
  getSmartContractInformation,
} from "../../services/web3ContractService";
import { fetchConfig } from "../../services/configService";
import { fetchAbi } from "../../services/abiService";

const walletErrorMessage =
  '<span><p>🦊 <a target="_blank" href="https://metamask.io/download.html" style="color:#8c5ce6;text-decoration: none;">You must install Cripto Wallet as Metamask, in your browser</a></p></span>';

// Fetch request
// smart contract request
const fetchLoadingDataOrConnectionRequest = () => {
  return {
    type: "FETCH_LOADING_DATA",
  };
};

// Fetch request successed
const fetchConfigDataSuccess = (payload) => {
  return {
    type: "FETCH_CONFIG_DATA",
    payload: payload,
  };
};

// Fetch request failed
const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

// smart contract request successed
const connectSuccessContract = () => {
  return {
    type: "CONNECTION_SUCCESS_CONTRACT",
  };
};

const connectSuccessAccount = (payload) => {
  return {
    type: "CONNECTION_SUCCESS_ACCOUNT",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

const fetchSmartContractDataSuccess = (payload) => {
  return {
    type: "CHECK_SMART_CONTRACT_DATA_SUCCESS",
    payload: payload,
  };
};

// smart contract request failed
const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

//--------------------------------------------//

export const fetchConfigDataAction = () => async (dispatch) => {
  try {
    dispatch(fetchLoadingDataOrConnectionRequest());

    const config = await fetchConfig();
    dispatch(fetchConfigDataSuccess(config));
  } catch (err) {
    console.error("Error in fetchConfigDataAction thunk:", err);
    throw err;
  }
};

export const checkWeb3Network = (config) => async (dispatch) => {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      dispatch(connectFailed(walletErrorMessage));
      return false;
    }

    const networkId = await ethereum.request({ method: "net_version" });
    if (parseInt(networkId, 10) === parseInt(config.network.id, 10)) {
      return ethereum;
    } else {
      dispatch(
        connectFailed(
          `Please switch to the ${config.network.name} (${config.network.symbol}) network and try again.`
        )
      );
      return false;
    }
  } catch (err) {
    console.error("Error checking the Web3 network:", err);
    dispatch(connectFailed("An error occurred while checking the network."));
    return false;
  }
};

export const connectWeb3Contract = () => async (dispatch) => {
  try {
    dispatch(fetchLoadingDataOrConnectionRequest());

    const [abi, config] = await Promise.all([fetchAbi(), fetchConfig()]);

    const ethereum = await dispatch(checkWeb3Network(config));
    if (!ethereum) {
      return false;
    }

    const contractAddress = config?.contractAddress;
    if (!contractAddress) {
      dispatch(connectFailed("Contract address not found in configuration."));
    } else {
      initializeBlockchain(ethereum, abi, contractAddress);
      dispatch(connectSuccessContract());
      return true;
    }
  } catch (err) {
    console.error("Failed to connect Web3 contract:", err);
    dispatch(connectFailed(err.message));
  }
};

export const fetchSmartContractData = () => async (dispatch) => {
  dispatch(fetchLoadingDataOrConnectionRequest());
  try {
    let smartContractInformation = await getSmartContractInformation();
    dispatch(
      fetchSmartContractDataSuccess({
        totalSupply: smartContractInformation.totalSupply.toString(),
        cost: smartContractInformation.cost.toString(),
        maxMintAmount: smartContractInformation.maxMintAmount.toString(),
      })
    );
  } catch (err) {
    dispatch(fetchDataFailed(err));
  }
};

/**
 * This method will check if the current wallet/account is connected with our dapp
 *
 * @returns
 */
export const checkWalletConnection = () => async (dispatch) => {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      dispatch(connectFailed(walletErrorMessage));
    }

    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length > 0) {
      const account = accounts[0];
      dispatch(updateAccount(account));
    }
  } catch (err) {
    dispatch(fetchDataFailed("Error checking wallet connection."));
    throw err;
  }
};

export const connectWallet = () => async (dispatch) => {
  try {
    dispatch(fetchLoadingDataOrConnectionRequest());

    const [abi, config] = await Promise.all([fetchAbi(), fetchConfig()]);

    const ethereum = await dispatch(checkWeb3Network(config));
    if (!ethereum) {
      return false;
    }

    const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    initializeBlockchain(ethereum, abi, config.contractAddress);

    dispatch(
      connectSuccessAccount({
        account: accounts[0],
      })
    );
  } catch (err) {
    console.error("Error during wallet connection:", err);
    dispatch(
      connectFailed(
        "An error occurred while connecting your wallet. Please try again."
      )
    );
  }
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchSmartContractData());
  };
};
