export const fetchConfig = async () => {
  try {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!configResponse.ok) {
      throw new Error(`HTTP error! status: ${configResponse.status}`);
    }

    const config = await configResponse.json();
    return config;
  } catch (err) {
    throw err;
  }
};
