import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Starfield from "../components/layout/Starfield";

const LayoutDefault = ({ children }) => {
  return (
    <div className="layout-container">
      <Starfield />
      <Header navPosition="right" className="reveal-from-bottom" />
      <main className="site-content">{children}</main>
      <Footer />
    </div>
  );
};

export default LayoutDefault;
