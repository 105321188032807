import React, { useEffect, useRef } from "react";

const Starfield = () => {
  const resizeObserverRef = useRef(null);

  // Function to update the CSS variables based on page dimensions
  const updateStarfieldDimensions = () => {
    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth
    );

    const height = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight
    );

    document.documentElement.style.setProperty("--page-width", `${width}px`);
    document.documentElement.style.setProperty("--page-height", `${height}px`);
  };

  useEffect(() => {
    // Update dimensions on component mount
    updateStarfieldDimensions();

    // Add resize event listener
    window.addEventListener("resize", updateStarfieldDimensions);

    // Set up ResizeObserver to detect content changes
    if (typeof ResizeObserver !== "undefined") {
      resizeObserverRef.current = new ResizeObserver(updateStarfieldDimensions);
      resizeObserverRef.current.observe(document.body);
    } else {
      // Fallback for browsers that don't support ResizeObserver
      const intervalId = setInterval(updateStarfieldDimensions, 1000);
      return () => clearInterval(intervalId);
    }

    // Cleanup function
    return () => {
      window.removeEventListener("resize", updateStarfieldDimensions);
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="starfield-container">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
};

export default Starfield;
