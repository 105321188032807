import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import xIcon from "./../../assets/images/icons/x-icon.svg";
import instagramIcon from "./../../assets/images/icons/instagram-icon.svg";
import discordIcon from "./../../assets/images/icons/discord-icon.svg";
import openseaIcon from "./../../assets/images/icons/opensea-icon.svg";
import etherscanIcon from "./../../assets/images/icons/etherscan-icon.svg";
import Logo from "../layout/partials/Logo";

const propTypes = {
  ...SectionProps.types,
};

const Introduction = ({
  className,
  topOuterDivider = false,
  bottomOuterDivider = false,
  topDivider = false,
  bottomDivider = false,
  hasBgColor = false,
  invertColor = false,
  ...props
}) => {
  const data = useSelector((state) => state.data);

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses} style={{ paddingTop: "30px" }}>
          <div className="hero-content">
            {data.errorMsg ? (
              <div
                style={{
                  color: "#8c5ce6",
                  textAlign: "center",
                  backgroundColor: "#25282c",
                  borderRadius: "10px",
                  marginBottom: "5px",
                  padding: "10px",
                }}
                dangerouslySetInnerHTML={{
                  __html: data.errorMsg,
                }}
              ></div>
            ) : (
              ""
            )}
            <div className="social-links">
              {[
                { href: data.config.xLink, src: xIcon, alt: "X", title: "X" },
                {
                  href: data.config.instagramLink,
                  src: instagramIcon,
                  alt: "Instagram",
                  title: "Instagram",
                },
                {
                  href: data.config.discordLink,
                  src: discordIcon,
                  alt: "Discord",
                  title: "Discord",
                },
                {
                  href: data.config.marketPlaceOpenseaLink,
                  src: openseaIcon,
                  alt: "Opensea",
                  title: "Opensea",
                },
                {
                  href: `${data.config.scanLink}${data.config.contractAddress}`,
                  src: etherscanIcon,
                  alt: "Etherscan",
                  title: "Etherscan",
                },
              ].map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-link"
                >
                  <img
                    src={item.src}
                    alt={item.alt}
                    title={item.title}
                    width={30}
                  />
                  <span className="tooltip">{item.title}</span>
                </a>
              ))}
            </div>
            <div className="container-sm">
              <h1>Welcome to Funky Planets - A Universe of Creativity!</h1>
              <Logo />
              <br />
              <p
                className="m-0 mb-24 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Step into the cosmic wonderland of Funky Planets, a
                one-of-a-kind platform where imagination meets the vastness of
                space! Whether you're an exoplanet enthusiast, a digital artist,
                or a curious explorer, Funky Planets is the ultimate hub for
                sharing and discovering uniquely crafted planets beyond our
                solar system.
              </p>

              <h4
                className="main-text reveal-from-bottom"
                data-reveal-delay="200"
              >
                What is Funky Planets?
              </h4>

              <div
                className="main-text reveal-from-bottom"
                data-reveal-delay="200"
              >
                <p>
                  Funky Planets is more than just a website—it’s a
                  community-driven space for exoplanet enthusiasts. Here, you
                  can:
                </p>
              </div>
              <div
                className="list-container reveal-from-bottom"
                data-reveal-delay="100"
              >
                <ul>
                  <li>
                    🚀 <b>Create & Share</b> your own exoplanet concepts—give
                    them names, histories, and unique ecosystems.
                  </li>
                  <li>
                    🌍 <b>Explore a collection</b> of incredible user-generated
                    exoplanets, each with its own fascinating lore and visuals.
                  </li>
                  <li>
                    💬 <b>Connect & Discuss</b> with other like-minded explorers
                    who love space, science fiction, and digital art.
                  </li>
                  <li>
                    🔗 <b>Collect exclusive NFT planets</b> minted on the
                    Ethereum blockchain.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Introduction.propTypes = propTypes;

export default Introduction;
